<template>
  <v-app v-touch="{
    left: () => {
      drawer = false;
    },
  }">
    <the-app-bar v-if="isAuthenticated" />
    <v-main class="" v-touch="{
      right: () => swipe('right'),
      left: () => swipe('left'),
      up: () => swipe('up'),
      down: () => swipe('down'),
    }">
      <router-view v-if="showMain" v-slot="{ Component }">
        <transition mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
      <loader-icon v-else />
    </v-main>
    <the-form-appbar />
    <v-footer padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} All Rights Reserved —
        <strong>BSS by Open World Latam</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import TheAppBar from "./components/TheAppBar.vue";
import TheFormAppbar from "./components/TheFormAppbar.vue";
import { version } from "../package";
import LoaderIcon from "./components/LoaderIcon.vue";

import essentialActions from "@/utils/essentialActions";

const HOST_ENV = process.env.VUE_APP_HOST_ENV;
export default {
  components: { TheFormAppbar, TheAppBar, LoaderIcon },
  name: "App",
  created() {
    //obtener del localstorage la variable 'firstTime'
    const firstTime = localStorage.getItem("firstTime");
  },
  beforeMount() {
    //Info app
    console.log(
      `%cBSS Check \nVersion: ${version}:${HOST_ENV}`,
      "color: #03a1fc; font-family:serif; font-size: 20px"
    );
    //prevencion de cierre
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
    //deteccion online
    window.addEventListener("online", () =>
      this.$store.commit("pwa/setOffline", false)
    );
    window.addEventListener("offline", () =>
      this.$store.commit("pwa/setOffline", true)
    );
  },
  mounted() { },

  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm("Existen Cambios sin Guardar ¿Salir sin terminar?")) {
        return;
      }
    }
    next();
  },

  data: () => ({
    showMain: false,
    channel: null,
  }),
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    },
    swipe(dir) {
      // this.$store.commit("setSwipe", dir);
    },
    onMsg(evt) {
      // console.log(`on msg`, evt);
    },
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/getIsAuthenticated"];
    },
    isEditing() {
      return this.$store.getters["getIsEditing"];
    },
  },
  watch: {
    isAuthenticated: {
      handler(newValue) {
        console.log("isAuthenticated", newValue);
        if (newValue) {
          Promise.all(
            essentialActions.map((action) => this.$store.dispatch(action))
          )
            .then(() => {
              //obtener del localstorage la variable 'firstTime'
              const firstTime = localStorage.getItem("firstTime");
              //si la variable es null, setear la variable a false y realizar location.reload()
              if (firstTime === null) {
                localStorage.setItem("firstTime", false);
                location.reload();
              }
              this.showMain = true;
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$router.push({ name: "login" });
          this.showMain = true;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>
.bordered {
  border: 1px solid #ccc;
  border-radius: 20px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

form {
  margin-left: 30px;
  margin-right: 30px;
}

.section-header {
  background-color: #134D5F;
  color: white;
  width: 100%;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 1.1rem;
}

.v-stepper__content {
  padding-left: 0px !important;
  padding-right: 1.5rem !important;
  margin-left: 0px !important;
}

* {
  -webkit-print-color-adjust: exact;
}
</style>
