<template>
  <div>
    <v-app-bar v-if="userInfo" app dark elevation="4" color="first">
      <v-app-bar-nav-icon @click="drawer = !drawer"> </v-app-bar-nav-icon>
      <v-toolbar-title style=""> {{ section }}</v-toolbar-title>
      <!-- offline indicator -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-if="isOffline" class="ml-3" color="warning" v-bind="attrs" v-on="on">
            mdi-cloud-alert
          </v-icon>
          <v-icon v-else class="ml-3" color="success" v-bind="attrs" v-on="on">
            mdi-cloud-check
          </v-icon>
        </template>
        <span>{{ isOffline ? "Modo Offline" : "Conectado" }}</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-btn to="/" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <template v-if="newUpdate" id="new-version" v-slot:extension>
        <div class="row g-0">
          <div class="col-12 col-sm-8 col-md-6">
            <v-sheet color="success" class="px-2 rounded g-0 m-0" elevation="2" style="cursor: pointer"
              @click="onUpdateClick">
              <div class="row justify-content-center g-0">
                <div class="col-2 col-sm-2 g-0 justify-content-center">
                  <v-icon color="white" large class="mx-2 g-0">mdi-update</v-icon>
                </div>
                <div class="col g-0 mt-1">
                  Nueva version - Click Aquí para actualizar
                </div>
              </div>
            </v-sheet>
          </div>
        </div>
      </template>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" v-if="userInfo" app temporary height="100%">
      <!-- User section -->
      <v-list-item id="company-info">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ companyName(userInfo.company) }}
          </v-list-item-title>
          <v-list-item-subtitle> {{ "BSS Check" }} </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="isAdmin">
          <v-btn icon :to="{ name: 'config' }">
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item id="user-info">
        <v-list-item-avatar color="first">
          <span class="white--text">{{ initials }}</span>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ userInfo.name }} {{ userInfo.last_name }}</v-list-item-title>
          <v-list-item-subtitle>{{ userInfo.email }} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item
        v-if="newUpdate"
        @click="onUpdateClick"
        id="new-version"
        class="green lighten-1 rounded-md white--text"
      >
        <v-list-item-action
          ><v-icon color="white">mdi-update</v-icon></v-list-item-action
        >
        <v-list-item-content>
          <v-list-item-title> Nueva actualizacion </v-list-item-title>
          <v-list-item-subtitle class="white--text"
            >Click aquí para actualizar</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item> -->
      <v-divider></v-divider>
      <!-- Navigation section -->
      <template v-for="(link, index) in linkList">
        <template v-if="hasPermission(link.permission)">
          <v-divider v-if="link.divider" :key="'dividerlist' + index"></v-divider>
          <v-list-item :id="`list-${link.to}`" :key="'list' + index" link :to="link.to">
            <v-list-item-action>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ link.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
      <template v-if="isAdmin">
        <v-divider> </v-divider>

        <v-list-item link to="/signup">
          <v-list-item-action>
            <v-icon>mdi-account-plus</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Crear Usuario</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <!-- <v-list-item id="allow-fs">
        <v-list-item-action><v-icon>mdi-counter</v-icon></v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ `Odometro` }}</v-list-item-title>
          <v-list-item-subtitle>Informar</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-switch></v-switch>
        </v-list-item-action>
      </v-list-item> -->

      <v-divider></v-divider>
      <!-- footer section -->
      <v-list-item v-if="isOffline" class="" @click="openModal()">
        <v-list-item-action>
          <v-icon>mdi-clock-time-two</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          Quedan {{ expiration }} horas
        </v-list-item-content>
      </v-list-item>

      <v-spacer></v-spacer>
      <template v-slot:append>
        <!-- <v-list-item v-if="isBrowser" ripple class="pa-0">
          <v-list-item-content class="">
            <v-sheet max-height="" color="blue darken-3" elevation="2"
              class="row g-0 rounded-md pa-1 pl-3 border border-dark" style="cursor: pointer" @click="installClick">
              <div class="col-1 mr-2">
                <v-sheet color="white" class="rounded-circle pa-1">
                  <v-img src="img/icons/512.png"></v-img>
                </v-sheet>
              </div>
              <div class="col d-flex align-items-center">
                <span class="body-1 text-center text-white">Descarga la App Aquí</span>
              </div>
            </v-sheet>
          </v-list-item-content>
        </v-list-item> -->
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <div id="log-out" class="pa-2">
          <v-btn block @click="signOut" color="red">Cerrar Sesión</v-btn>
        </div>
        <v-footer id="side-footer" padless style="opacity: 0.5; font-size: smaller">
          <v-col class="text-center" cols="12">
            {{ new Date().getFullYear() }} —
            <strong>BSS Check {{ version
            }}{{ HOST === "development" ? ":DEV" : "" }}
            </strong>
          </v-col>
        </v-footer>
      </template>
    </v-navigation-drawer>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>Modo Offline</v-card-title>
        <v-card-subtitle>La aplicacion se encuentra en modo offline</v-card-subtitle>
        <v-card-text>
          <p>
            Puedes llenar formularios por las proximas
            {{ expiration }} horas. Luego de eso tendras que abrir la aplicacion
            teniendo conexion a internet.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" large>OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
let beforeInstallEvent;
import { version } from "../../package";
const HOST_ENV = process.env.VUE_APP_HOST_ENV;
export default {
  name: "TheAppBar",
  data: () => ({
    drawer: false,
    version: version,
    showExtended: true,
    dialog: false,
    HOST: HOST_ENV,
    group: true,
    linkList: [
      {
        icon: "mdi-home",
        to: "/menu",
        name: "Menú Principal",
        divider: false,
        permission: "",
      },
      // {
      //   icon: "mdi-text-box-plus-outline",
      //   to: "/forms/selection",
      //   name: "Nuevo Formulario",
      //   divider: false,
      //   permission: "form:create",
      // },
      // {
      //   icon: "mdi-text-box-multiple-outline",
      //   to: "/forms/table/view",
      //   name: "Revisar Formularios",
      //   divider: false,
      //   permission: "form:read",
      // },
      // {
      //   icon: "mdi-account-plus",
      //   to: "/signup",
      //   name: "Crear Usuario",
      //   divider: true,
      //   permission: "tempform:create",
      // },
    ],
  }),
  mounted() {
    // btn de instalacion
    window.addEventListener("beforeinstallprompt", (e) => {
      beforeInstallEvent = e;
    });
  },
  methods: {
    signOut() {
      this.$store.dispatch("auth/signOut").then(() => {
        this.drawer = false;
        this.$router.push({ name: "login" });
      });
    },
    hasPermission(permission) {
      return permission ? this.userPermissions.includes(permission) : true;
    },
    onUpdateClick() {
      window.location.reload();
    },
    companyName(copname) {
      //be copname a string with the substring '_mgr', remove it and return the rest upper case
      return copname.replace("_mgr", "").toUpperCase();
    },
    openModal() {
      this.drawer = false;
      this.dialog = true;
    },
    installClick: async () => {
      if (beforeInstallEvent) {
        beforeInstallEvent.prompt();
        const { outcome } = await beforeInstallEvent.userChoice;
        if (outcome === "accepted") {
          beforeInstallEvent = null;
        }
      }
    },
  },
  computed: {
    showSignOut() {
      return this.$route.name !== "login";
    },
    userInfo() {
      return this.showSignOut ? this.$store.getters["auth/userInfo"] : null;
    },
    section() {
      return this.$route.meta.section ?? "BSS Check";
    },
    userPermissions() {
      return this.$store.getters["auth/userPermissions"];
    },
    initials() {
      return this.userInfo.name.charAt(0) + this.userInfo.last_name.charAt(0);
    },
    newUpdate() {
      return this.$store.getters["pwa/newUpdateAvailable"];
    },
    newOnMenu() {
      return this.newUpdate ?? false;
    },
    isAdmin() {
      return this.userInfo.user_role === "admin";
    },
    isSwipe() {
      return this.$store.getters["getSwipe"];
    },
    isOffline() {
      return this.$store.getters["pwa/isOffline"];
    },
    expiration() {
      const expirationTimeStamp = this.$store.getters["auth/expiration"];
      // given expirationTimestamp in seconds, convert to milliseconds
      const expiration = new Date(expirationTimeStamp * 1000);
      //given expiration, return how many hours and minutes are left
      const hours = Math.floor(
        (expiration.getTime() - new Date().getTime()) / 1000 / 60 / 60
      );
      const minutes = Math.floor(
        (expiration.getTime() - new Date().getTime()) / 1000 / 60
      );
      return `${hours}`;
    },
    isBrowser() {
      return window.matchMedia("(display-mode: browser)").matches;
    },
  },
  watch: {
    isSwipe(newval, oldVal) {
      if (newval == "right") {
        this.drawer = true;
      } else if (newval == "left") {
        this.drawer = false;
      }
    },
  },
};
</script>

<style></style>