import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

const SKIN = process.env.VUE_APP_SKIN ?? "fleetsafe";

const customColors = {
  fleetsafe: {
    first: "#023E8A",
    second: "#5fb0b7",
    third: "#157145",
    fourth: "#f9a620",
    fifth: "#F6EFA6",
  },
  bss: {
    first: "#134D5F",
    second: "#00AABD",
    third: "#157145",
    fourth: "#f9a620",
    fifth: "#F6EFA6",
  },
};

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
  },
  theme: {
    themes: {
      light: {
        ...customColors[SKIN],
        primary: "#134D5F",
        secondary: "#94E8B4",
        accent: "#72BDA3",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
});
